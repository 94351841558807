export default {
    cs: {
        code: {
            GENERIC_ERROR: 'Something went wrong',
            CONFLICT_ERROR: 'Došlo ke konfliktu. Záznam již pravděpodobně existuje.',
            GENERIC_ERROR_404: 'Not found.',
            EDIT_NOT_ALLOWED: 'Položku nelze upravit.',
            ACCOUNT_CREATED: 'ACCOUNT_CREATED',
            LOGGED_IN: 'LOGGED_IN',
            LOGGED_OUT: 'LOGGED_OUT',
            ACCOUNT_EXISTS: 'Účet už existuje',
            PRODUCT_EXISTS: 'Tento produkt již existuje.',
            UNAUTHORIZED: 'UNAUTHORIZED',
            PRODUCT_NOT_FOUND: 'Produkt nebyl nalezen.',
            ID_OR_CODE_REQUIRED: 'Id nebo kód vyžadován.',
            PRODUCT_DELETED: 'Produkt byl odstraněn.',
            USER_NOT_FOUND: 'Uživatel nebyl nalezen.',
            NO_PERMISSION: 'NO_PERMISSION',
            SUPPLIER_EXISTS: 'Dodavatel již existuje.',
            SUPPLIER_NOT_FOUND: 'Dodavatel nebyl nalezen.',
            SUPPLIER_GLN_MISSING: 'Dodavatel nemá vyplněné GLN.',
            RESERVATION_PROMO_EXISTS: 'V tento datum již existuje rezervace akčního FRC.',
            COMPUTE_COOLDOWN: 'Přepočítání v tuto chvíli ještě není k dispozici, vyčkejte.',
        },
        users: 'Users',
        tables: {
            search: 'Vyhledat...',
        },
        login: {
            validation: {
                incorrectData: 'Incorrect data entered',
                empty: {
                    login: 'Provide username',
                    password: 'Provide password',
                },
            },
        },
    },
    en: {},
};
