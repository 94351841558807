import { IUser } from '@/models/user.models';

export enum Language {
  EN = 'en',
  CS = 'cs',
  DE = 'de',
};

export interface RootState {
  user: IUser;
  cache: any;
  // empty
};

export const state: RootState = {
  user: {} as IUser,
  cache: {}
};
