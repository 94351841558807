import { AxiosResponse } from 'axios';
import Repository from '@/api/Repository';

import service from '@/api/api.services';
import { IGetContactResponse, IGetContactsResponse } from '@/models/contact.models';
import { IGlobal } from '@/models/global.models';

const resource = 'global';

export default class GlobalRepository {
    public static getAll = async (): Promise<AxiosResponse<IGlobal[]>> => {
        try {
            return Repository.get(`${resource}`);
        } catch (error) {
            return error;
        }
    };
    public static get = async (name: string): Promise<AxiosResponse<IGlobal>> => {
        try {
            return Repository.get(`${resource}/${name}`);
        } catch (error) {
            return Promise.reject(error);
        }
    };
}
