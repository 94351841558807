import UserRepository from '@/api/user/user.repository';
import SaleRepository from './sale/sale.repository';
import GlobalRepository from './global/global.repository';
import SubscriberRepository from './subscriber/subscriber.repository';
import FileRepository from './gallery/file.repository';

class Repositories {
    static repositories: any = {
        user: UserRepository,
        sale: SaleRepository,
        global: GlobalRepository,
        subscriber: SubscriberRepository,
        file: FileRepository,
    };
}

export default class RepositoryFactory {
    public static get = (name: string) => {
        return Repositories.repositories[name];
    };
}
