import { AxiosResponse } from 'axios';
import Repository from '@/api/Repository';

import service from '@/api/api.services';

const resource = 'ext/sale';

import { ISale } from '@/models/sale.models';

export default class SaleRepository {
    public static getAll = async (): Promise<ISale[]> => {
        try {
            const response: AxiosResponse<ISale[]> = await Repository.get(`${resource}`);
            return response.data;
        } catch (error) {
            throw error;
        }
    };
    public static getById = async (id: number): Promise<ISale> => {
        try {
            const response: AxiosResponse<ISale> = await Repository.get(`${resource}/${id}`);
            return response.data;
        } catch (error) {
            throw error;
        }
    };
    public static downloadLeaflet = async (id: number): Promise<ArrayBuffer> => {
        try {
            const response: AxiosResponse<ArrayBuffer> = await Repository.get(`${resource}/${id}/leaflet`, {
                responseType: 'arraybuffer',
            });
            return response.data;
        } catch (error) {
            throw error;
        }
    };
}
