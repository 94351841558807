import { AxiosResponse } from 'axios';
import Repository from '@/api/Repository';

import service from '@/api/api.services';
import { ISubscriber, ISubscribersProducts, ISubscriberWarehouse } from '@/models/subscriber.models';

const resource = 'ext/subscriber';

export default class SubscriberRepository {
    public static getAll = async (): Promise<AxiosResponse<ISubscriber[]>> => {
        try {
            return Repository.get(`${resource}`);
        } catch (error) {
            return error;
        }
    };
    public static create = async (subscriber: ISubscriber): Promise<AxiosResponse<boolean>> => {
        try {
            return Repository.post(`${resource}`, JSON.stringify(subscriber));
        } catch (error) {
            return error;
        }
    };

    public static getAutomaticListing = async (id: number): Promise<AxiosResponse<number[]>> => {
        try {
            return Repository.get(`${resource}/${id}/listing/auto`);
        } catch (error) {
            return error;
        }
    };

    public static batchAddListing = async (id: number, products: number[]): Promise<AxiosResponse<void>> => {
        try {
            return Repository.post(`${resource}/${id}/listing/batch`, JSON.stringify(products));
        } catch (error) {
            return error;
        }
    };

    public static addListing = async (
        id: number,
        data: ISubscribersProducts
    ): Promise<AxiosResponse<ISubscribersProducts>> => {
        try {
            return Repository.post(`${resource}/${id}/listing`, JSON.stringify(data));
        } catch (error) {
            return error;
        }
    };

    public static getListing = async (
        id: number,
        data: ISubscribersProducts
    ): Promise<AxiosResponse<ISubscribersProducts>> => {
        try {
            return Repository.get(`${resource}/${id}/listing/${data.product_id}`);
        } catch (error) {
            return error;
        }
    };

    public static updateListing = async (
        id: number,
        data: ISubscribersProducts
    ): Promise<AxiosResponse<ISubscribersProducts>> => {
        try {
            return Repository.put(`${resource}/${id}/listing/${data.product_id}`, JSON.stringify(data));
        } catch (error) {
            return error;
        }
    };

    public static deleteListing = async (
        id: number,
        data: ISubscribersProducts
    ): Promise<AxiosResponse<ISubscribersProducts>> => {
        try {
            return Repository.delete(`${resource}/${id}/listing/${data.product_id}`);
        } catch (error) {
            return error;
        }
    };

    public static update = async (subscriber: ISubscriber): Promise<AxiosResponse<boolean>> => {
        try {
            return Repository.put(`${resource}/${subscriber.id}`, JSON.stringify(subscriber));
        } catch (error) {
            return error;
        }
    };
    public static delete = async (id: number): Promise<AxiosResponse<boolean>> => {
        try {
            return Repository.delete(`${resource}/${id}`);
        } catch (error) {
            return error;
        }
    };

    public static get = async (id: string): Promise<AxiosResponse<ISubscriber>> => {
        try {
            return Repository.get(`${resource}/${id}`);
        } catch (error) {
            return Promise.reject(error);
        }
    };

    public static getWarehouses = async (id: number): Promise<AxiosResponse<ISubscriberWarehouse[]>> => {
        try {
            return Repository.get(`${resource}/${id}/warehouse`);
        } catch (error) {
            return Promise.reject(error);
        }
    };

    public static createWarehouse = async (
        idSubscriber: number,
        warehouse: ISubscriberWarehouse
    ): Promise<AxiosResponse<ISubscriberWarehouse>> => {
        try {
            return Repository.post(`${resource}/${idSubscriber}/warehouse`, JSON.stringify(warehouse));
        } catch (error) {
            return Promise.reject(error);
        }
    };

    public static updateWarehouse = async (
        idSubscriber: number,
        idWarehouse: number,
        warehouse: ISubscriberWarehouse
    ): Promise<AxiosResponse<ISubscriberWarehouse>> => {
        try {
            return Repository.put(`${resource}/${idSubscriber}/warehouse/${idWarehouse}`, JSON.stringify(warehouse));
        } catch (error) {
            return Promise.reject(error);
        }
    };

    public static deleteWarehouse = async (
        idSubscriber: number,
        idWarehouse: number
    ): Promise<AxiosResponse<ISubscriberWarehouse>> => {
        try {
            return Repository.delete(`${resource}/${idSubscriber}/warehouse/${idWarehouse}`);
        } catch (error) {
            return Promise.reject(error);
        }
    };

    public static getWarehouse = async (
        idSubscriber: number,
        idWarehouse: number
    ): Promise<AxiosResponse<ISubscriberWarehouse>> => {
        try {
            return Repository.get(`${resource}/${idSubscriber}/warehouse/${idWarehouse}`);
        } catch (error) {
            return Promise.reject(error);
        }
    };
}
