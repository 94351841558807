import axios, { AxiosRequestConfig, AxiosError, AxiosResponse } from 'axios';
import service from '@/api/api.services';
import constants from '@/config';
import removeToken from '@/utils/removeToken';
import router from '@/router';
import Vue from 'vue';
import i18n from '@/i18n/it18n';
// import store from '@/store/store';

// import toasted from 'vue-toasted';

const baseURL: string = constants.BASE_URL;

axios.interceptors.request.use(
    (config: AxiosRequestConfig): any => {
        config.baseURL = baseURL;
        config.headers = {
            ...config.headers,
            'Content-Type': 'application/json',
        };

        config.withCredentials = true;
        if (!config.headers['anonymous']) {
            const token = service.getToken();

            if (token) {
                delete config.headers['anonymous'];
                config.headers['Authorization'] = 'JWT ' + token;
                return config;
            }
            return config;
        } else {
            return config;
        }
    },
    (error: AxiosError) => {
        Promise.reject(error);
    }
);

axios.interceptors.response.use(
    (response: AxiosResponse<any>): any => {
        return response;
    },
    async (error) => {
        if (error.response) {
            switch (error.response.status) {
                case 401: {
                    if (error.response.data !== 'Unauthorized') {
                        removeToken();
                        router.push({ name: 'login' });
                        Vue.toasted.error('Byl jste odhlášen z důvodu neaktivity.', {
                            fullWidth: true,
                            action: [],
                            closeOnSwipe: true,
                            fitToScreen: true,
                            duration: 5000,
                        });
                    }
                    return Promise.reject(error.response);
                }
                default: {
                    const errData: any = error.response?.data ?? {};
                    const status: number = error.response.status;
                    let code: string = errData.code ?? errData.msg ?? 'GENERIC_ERROR';
                    if (code === 'GENERIC_ERROR' && [404].indexOf(status) >= 0) {
                        code = `${code}_${status}`;
                    }
                    Vue.toasted.error(i18n.tc(`code.${code}`), {
                        fullWidth: true,
                        action: [],
                        closeOnSwipe: true,
                        fitToScreen: true,
                        duration: 5000,
                    });
                    return Promise.reject(error.response);
                }
            }
        } else if (error.request) {
            Vue.toasted.error('Nepodařilo se navázat spojení s aplikačním serverem.', {
                fullWidth: true,
                action: [],
                closeOnSwipe: true,
                fitToScreen: true,
                duration: 5000,
            });
            return Promise.reject(error);
        } else {
            console.log(error);
            return Promise.reject(error);
        }
    }
);

export default axios;
