import Vue from 'vue';
import Vuex from 'vuex';

import VuexPersist, { VuexPersistence } from 'vuex-persist';

import createPersistedState from 'vuex-persistedstate';


import { state, RootState } from '@/store/state';
import actions from '@/store/actions';
import mutations from './mutations';

Vue.use(Vuex);



export default new Vuex.Store<RootState>({
  strict: true,
  state: state,
  actions: actions,
  mutations: mutations,
  plugins: [createPersistedState()]
});
