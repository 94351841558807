import cookies from 'js-cookie';
import cryptoRandomString from 'crypto-random-string';

class ApiService {
    static getToken = (): string | null => {
        return cookies.get('jwt') || null;
    };

    static getAuthToken = (): string => {
        return cryptoRandomString({ length: 64, type: 'url-safe' });
    };

    static anonymous = (obj?: any) => {
        if (typeof obj === 'object' && typeof obj.headers === 'object') {
            obj.headers.anonymous = 1;
            return obj;
        } else if (typeof obj === 'object') {
            return {
                ...obj,
                headers: {
                    anonymous: 1,
                },
            };
        } else {
            return {
                headers: {
                    anonymous: 1,
                },
            };
        }
    };
}

export default ApiService;
