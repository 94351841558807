import Vue from 'vue';
import VueRouter, { NavigationGuardNext, Route, RouteConfig, RouteRecord } from 'vue-router';

// components
import Login from '@/views/no_auth/Login.vue';
import Error403 from '@/components/errors/Error403.vue';
import Sales from '@/views/auth/Sales.vue';
import SubscriberDetail from '@/views/auth/SubscriberDetail.vue';

// utils
import isAuth from '@/utils/isAuth';
import store from '@/store/store';

import { IUser, IRole } from '@/models/user.models';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
    {
        path: '',
        component: () => import('@/NoAuth.vue'),
        meta: {
            auth: false,
        },
        children: [
            {
                path: '/login',
                name: 'login',
                meta: {
                    title: 'Login',
                },
                component: Login,
            },
        ],
    },
    {
        path: '',
        component: () => import('@/Auth.vue'),
        meta: {
            auth: true,
        },
        children: [
            {
                path: '/cleardata',
                name: 'dataclear',
                meta: {
                    title: 'Clear Data',
                },
                beforeEnter: async () => {
                    store.dispatch('clearData');
                    location.href = '/';
                },
            },
            {
                path: '/trade/promo',
                name: 'sales',
                meta: {
                    title: 'Promo leaflets',
                },
                component: Sales,
            },
        ],
    },
    {
        path: '',
        component: () => import('@/views/FullPage.vue'),
        children: [
            {
                path: '/error/403',
                name: 'error-403',
                component: Error403,
            },
        ],
    },
    {
        path: '/*',
        beforeEnter: async (to, from, next) => {
            console.log('Hitting this hard!');
            if (isAuth()) {
                next({ path: '/trade/promo' });
            } else {
                next({ path: '/login' });
            }
        },
    },
];

const router = new VueRouter({
    mode: 'history',
    routes,
});

const hasPermission = (routeRoles: string[] | undefined, userRoles: IRole[] | undefined): boolean => {
    if (typeof routeRoles === 'undefined' || !routeRoles.length) return true;
    if (typeof userRoles === 'undefined' || !userRoles.length) return false;
    for (let i = 0; i < routeRoles.length; i++) {
        const routeRole: string = routeRoles[i];
        if (userRoles.find((userRole: IRole) => userRole.name === routeRole)) {
            return true;
        }
    }
    return false;
};

// const neededCRMRole = 'USER_CRM';
// const neededSCMRole = 'USER_SCM';
// const hasCRMPermission = (userRoles: IRole[] | undefined): boolean => {
//     if (typeof userRoles === 'undefined' || !userRoles.length) return false;
//     return !!userRoles.find((x: IRole) => neededCRMRole == x.name);
// };
// const hasSCMPermission = (userRoles: IRole[] | undefined): boolean => {
//     if (typeof userRoles === 'undefined' || !userRoles.length) return false;
//     return !!userRoles.find((x: IRole) => neededSCMRole == x.name);
// };

router.beforeEach(async (to, from, next) => {
    document.title = to?.meta?.title ?? 'SILISAN';
    if (to.path === '/cleardata') {
        next();
        return;
    }
    if (to.path === '/') {
        if (isAuth()) {
            next({ path: '/trade/promo' });
        } else {
            next({ path: '/login' });
        }
    }
    if (to.matched.some((record) => record.meta.auth === true)) {
        if (!isAuth()) {
            next({
                path: '/login',
                query: { path: to.path },
                replace: true,
            });
        } else {
            const userData: IUser = await store.dispatch('getUserData');
            const routeRoles: string[] | undefined = to?.meta?.roles;
            if (userData.type === 'EXTERNAL') {
            }
            if (hasPermission(routeRoles, userData?.roles)) {
                next();
            } else {
                router.push({ name: 'error-403' });
            }
        }
    } else if (to.matched.some((record) => record.meta.auth === false)) {
        if (isAuth()) {
            next({
                path: '/trade/promo',
            });
        } else {
            next();
        }
    } else {
        next();
    }
});

export default router;
