import { RootState } from '@/store/state';
import { IUser } from '@/models/user.models';

const mutations = {
    UPDATE_USER_DATA(state: RootState, payload: IUser) {
        state.user = payload;
    },
    LOG_OUT(state: RootState) {
        state.user = {} as IUser;
        state.cache = {};
    },

    UPDATE_CACHE(state: RootState, payload: any) {
        state.cache[payload.id] = Date.now() + payload.time
    }
}

export default mutations;