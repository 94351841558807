
import { Component, Vue, PropSync, Prop } from 'vue-property-decorator';
import RepositoryFactory from '@/api/RepositoryFactory';
import { IFolder } from '@/models/gallery.models';
import ApiService from '@/api/api.services';
import moment from 'moment';

const fileRepo = RepositoryFactory.get('file');

@Component({
    name: 'gallery-modal',
})
export default class GalleryModal extends Vue {
    private isLoading = false;

    private base_url = process.env.VUE_APP_API_URL;

    private folder: IFolder | null = null;

    @PropSync('openModal', { type: Boolean }) syncedOpenModal!: boolean;

    @Prop(String) folder_id!: string;
    @Prop(String) subtitle?: string;

    created() {
        if (!this.folder_id) {
            console.error('Folder ID not supplied!');
            this.isLoading = true;
            return;
        }
        this.getFolder();
    }

    private async getFolder(): Promise<void> {
        try {
            this.isLoading = true;
            this.folder = await fileRepo.getFolder(this.folder_id);
            this.folder?.files?.sort((a, b) => moment(b.createdAt).valueOf() - moment(a.createdAt).valueOf());
        } catch (e) {
            console.error(e);
        } finally {
            this.isLoading = false;
        }
    }

    private get folderItems() {
        return this.folder?.files
            ?.filter((f) => f.mimetype.indexOf('image') >= 0)
            ?.map((f) => {
                const token = this.getToken();
                const auth = this.getAuthToken();
                return {
                    src: `${this.base_url}/ext/file/${f.id}/download?auth=${auth}&token=${token}`,
                    thumbnail: `${this.base_url}/ext/file/${f.id}/thumbnail?auth=${auth}&token=${token}`,
                    w: 0,
                    h: 0,
                    alt: f.name,
                    title: f.name,
                };
            });
    }

    private closeModal() {
        this.$emit('close');
    }

    private getToken() {
        return ApiService.getToken();
    }

    private getAuthToken() {
        return ApiService.getAuthToken();
    }
}
