
import { Component, Vue } from 'vue-property-decorator';
import repository from '@/api/RepositoryFactory';
import { ISale } from '@/models/sale.models';
import { formatDate } from '@/utils/dateUtils';
import GalleryModal from '@/components/gallery/GalleryModal.vue';
import { BvTableFieldArray } from 'bootstrap-vue';
import ApiService from '@/api/api.services';
import CTableFooter from '@/components/table/SilTableFooter.vue';

const saleRepo = repository.get('sale');

function debounce(func: Function, timeout = 300): Function {
    let timer: number;
    return () => {
        clearTimeout(timer);
        timer = setTimeout(() => {
            func();
        }, timeout);
    };
}

@Component({
    components: {
        'gallery-modal': GalleryModal,
        'sil-table-footer': CTableFooter,
    },
})
export default class Sales extends Vue {
    sales: ISale[] = [];
    //
    galleryModalFolderId: string | null = null;
    galleryModalSubtitle = '';
    isGalleryModalOpened = false;
    pageSize = 6;
    currentPage = 1;
    totalSales = 0;
    //
    tableFields: BvTableFieldArray = [
        {
            key: 'product.originName',
            label: 'Product',
        },
        // {
        //     key: 'promoPrice',
        //     label: 'Promo Price',
        // },
    ];
    reconfigurePageSize() {
        Vue.nextTick(() => {
            const gridComputedStyle = window.getComputedStyle(this.$refs.mainGrid as any);
            // get number of grid rows
            const gridRowCount = gridComputedStyle.getPropertyValue('grid-template-rows').split(' ').length;
            // get number of grid columns
            const gridColumnCount = gridComputedStyle.getPropertyValue('grid-template-columns').split(' ').length;
            console.log({ gridRowCount, gridColumnCount });
            this.pageSize = Math.max(4, gridRowCount * gridColumnCount);
        });
    }
    //
    created() {
        const magicNumber = 300;
        this.pageSize = Math.round(window.innerWidth / magicNumber);
        const debouncer = debounce(() => {
            this.pageSize = Math.round(window.innerWidth / magicNumber);
            this.reconfigurePageSize();
        }, 250);
        addEventListener('resize', () => {
            debouncer();
        });
        this.getSales().then(() => {
            this.reconfigurePageSize();
        });
    }
    async getSales() {
        try {
            this.sales = await saleRepo.getAll();
            this.totalSales = this.sales.length;
        } catch (e) {
            console.error(e);
        }
    }
    getPage(iPage: number): ISale[] {
        const page = iPage - 1;
        const toSkip = page * this.pageSize;
        return this.sales.slice(toSkip, toSkip + this.pageSize);
    }
    renderDate(dt: string): string {
        return formatDate(dt);
    }
    openGalleryModal(sale: ISale) {
        if (!sale || !sale.folder_id) return;
        this.galleryModalFolderId = sale.folder_id;
        this.galleryModalSubtitle = `<b>${sale.saleNumber}</b> - ${sale.subscriber?.name}<br/>${formatDate(
            sale.dateStart
        )} - ${formatDate(sale.dateFinish)}`;
        this.isGalleryModalOpened = true;
    }
    closeGalleryModal() {
        this.isGalleryModalOpened = false;
        this.galleryModalFolderId = null;
        this.galleryModalSubtitle = '';
    }
    generateLeafletHref(file_id: string) {
        const auth = ApiService.getAuthToken();
        const token = ApiService.getToken();
        return `${process.env.VUE_APP_API_URL}/ext/file/${file_id}/download?auth=${auth}&token=${token}`;
    }
}
