import { AxiosResponse } from 'axios';
import Repository from '@/api/Repository';
import { ILoginParams, ILoginResponse, ILogoutResponse, IUser } from '@/models/user.models';

import service from '@/api/api.services';

const resource = '';

export default class UserRepository {
    public static login = async (params: ILoginParams): Promise<AxiosResponse<ILoginResponse>> => {
        try {
            const body: string = JSON.stringify(params);
            return Repository.post(`${resource}/login`, body, service.anonymous());
        } catch (error) {
            throw error;
        }
    };
    public static logout = async (): Promise<AxiosResponse<ILogoutResponse>> => {
        try {
            return Repository.post(`${resource}/logout`, service.anonymous());
        } catch (error) {
            throw error;
        }
    };
    public static getUserData = async (): Promise<AxiosResponse<IUser>> => {
        try {
            return Repository.get(`${resource}/userdata`);
        } catch (error) {
            throw error;
        }
    };
}
