// import store from '@/store/state';
import { ActionContext, Action, createLogger } from 'vuex';
import { AxiosResponse } from 'axios';
import { RootState } from '@/store/state';

import factory from '@/api/RepositoryFactory';
import { IUser, ILoginParams } from '@/models/user.models';
import removeToken from '@/utils/removeToken';

const user = factory.get('user');
const actions = {
    // / vzor
    async login({ commit, state }: ActionContext<RootState, RootState>, params: ILoginParams) {
        try {
            const res: AxiosResponse<IUser> = await user.login(params);
            const data = res.data;
            commit('UPDATE_USER_DATA', data);
            commit('UPDATE_CACHE', { id: 'user', time: 300000 }); //TODO: increase caching time
            return data;
        } catch (error) {
            console.log(error);
            throw error;
        }
    },

    async logout({ commit, state }: ActionContext<RootState, RootState>) {
        try {
            await user.logout();
            commit('LOG_OUT');
        } catch (error) {
            // If server is dead, remove token locally
            removeToken();
            commit('LOG_OUT');
            throw error;
        }
    },

    async getUserData({ commit, state }: ActionContext<RootState, RootState>) {
        try {
            if (!state.user || !state.cache['user'] || state.cache['user'] < Date.now()) {
                const res: AxiosResponse<IUser> = await user.getUserData();
                const data = res.data;
                commit('UPDATE_USER_DATA', data);
                commit('UPDATE_CACHE', { id: 'user', time: 300000 }); //TODO: increase caching time
                return data;
            } else {
                return state.user;
            }
        } catch (error) {
            throw error;
        }
    },

    clearData({ commit, state }: ActionContext<RootState, RootState>) {
        try {
            commit('UPDATE_USER_DATA', null);
            commit('UPDATE_CACHE', { id: 'user', time: -1 });
        } catch (error) {
            throw error;
        }
    },
};

export default actions;
